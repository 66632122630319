import React from "react";
import get from "lodash/get";
import GeoJsonBounds from "geojson-bounds";
import Map from "../../../../components/map/map";

import "../style.scss";

import BoundsGeoJSON from "../richmond-district.json";

class BoundsMap extends React.Component {

  constructor(props) {
    super(props);
    this.bounds = GeoJsonBounds.extent(BoundsGeoJSON);
    console.log(this.bounds);
  }

  render() {
    return (
      <div className="running-the-richmond-map">
        <div className="map-container">
          <Map
            sources={{
              bounds: { type: "geojson", data: BoundsGeoJSON },
            }}
            layers={[
              {
                id: "bounds",
                type: "fill",
                source: "bounds",
                layout: {},
                paint: {
                  "fill-color": "#088",
                  "fill-opacity": 0.8,

                },
              },
            ]}
            boundingBox={this.bounds}
            center={[-122.4804671, 37.7803062]}
            style="mapbox://styles/mapbox/dark-v9"
            zoom={9}
            pitch={0}
            bearing={0}
          />
        </div>
      </div>
    );
  }
}

export default BoundsMap;
